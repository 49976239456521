
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import { Api } from "@/assets/Api";
import { ElMessage } from "element-plus";
import store from "@/store";
import RegisterTime from "./RegisterTime.vue";

interface Column {
  label: string;
  prop: string;
  search: boolean;
  regex: boolean;
  rules: [];
}
export default defineComponent({
  name: "ConfigDoc",
  components: { RegisterTime },
  setup() {
    let data = reactive<any>({
      init: false,
      data: {},
      option: {},
      page: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20],
        layout: "total, sizes, prev, pager, next",
      },
      query: {
        limit: 10,
        page: 1,
        where: {},
        sort: "-OrderNum",
      },
      timeDialogVisible: false,
      timeStatus: [],
    });

    const searchChange = (params: any, done: any) => {
      console.log(params);
      if (!params["hospital"]) {
        ElMessage.warning("请选择医院");
        done();
        return;
      }
      for (let p in params) {
        const field: Column = data.option.column.find(
          (v: Column) => v.prop === p
        );
        if (field.regex) {
          params[p] = { $regex: params[p] };
        }
      }
      data.query.where = params;
      data.page.currentPage = 1;
      data.query.page = 1;
      data.query.limit = data.page.pageSize;
      getList();
      done();
    };

    const changePage = ({ pageSize, currentPage }: any) => {
      if (!data.query.where["hospital"]) {
        return;
      }
      data.query.page = currentPage;
      data.query.limit = pageSize;
      getList();
    };

    const getList = () => {
      console.log(data.query);
      Api.Config.GetDocList(data.query).then((res: any) => {
        console.log(res);
        data.data = {};
        if (res.Code == 0) {
          data.data = res.Data;
          data.page.total = res.Data.total;
          data.page.currentPage = res.Data.page;
          data.data.data.forEach((element: any) => {
            element.hospitalid = element.hospitalid + "";
            element.RegisterStatus = element.RegisterStatus == 0 ? true : false;
          });
        } else {
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
        }
      });
    };

    const getOption = () => {
      Api.Config.GetDocListOption().then((res: any) => {
        console.log(res);
        const dockingHospital: string = store.getters.dockingHospital;
        if (dockingHospital) {
          const jsonHospital = JSON.parse(dockingHospital);
          // jsonHospital.forEach((element: any) => {
          //   element.value = element.value + "";
          // });
          res.column.filter((c: any) => {
            if (c.prop == "hospital") c.dicData = jsonHospital;
          });
        }
        data.option = res;
        console.log(res);

        data.init = true;
      });
    };

    const rowUpdate = (form: any, index: any, done: any, loading: any) => {
      var action: any = {
        RegisterStatus: form.RegisterStatus ? 0 : 1,
        HospitalId: form.HospitalId,
        DocId: form.DocId,
        Gender: form.Gender,
        DocDescript: form.DocDescript,
        PhotoURL: form.PhotoURL,
      };
      Api.Config.EditDoc(form.DocId, action).then((res: any) => {
        if (res.Code == 0) {
          ElMessage.success("保存成功");
          getList();
          done();
        } else {
          loading();
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
        }
      });
    };

    const sortableChange = (oldindex: number, newindex: number) => {
      if (
        !data.query.where["hospital"] ||
        !data.query.where["DeptName"] ||
        data.query.where["DocName"]
      ) {
        ElMessage.warning({
          showClose: true,
          duration: 10000,
          message: "请选择一个科室，清空医生查询条件，查询后进行排序",
        });
        return;
      }
      if (oldindex == newindex) return;
      const order = {
        HospitalID: -1,
        key: data.data.data[newindex].DocId,
        steps: oldindex - newindex,
      };
      Api.Config.OrderDoc(order).then((res: any) => {
        if (res.Code == 0) {
          ElMessage.success("保存成功");
        } else {
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
          getList();
        }
      });
    };

    onMounted(() => {
      getOption();
    });

    return {
      ...toRefs(data),
      searchChange,
      changePage,
      rowUpdate,
      sortableChange,
    };
  },
});
