import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_avue_crud = _resolveComponent("avue-crud")!

  return (_ctx.init)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_avue_crud, {
          ref: "crud",
          data: _ctx.data.data,
          option: _ctx.option,
          page: _ctx.page,
          "onUpdate:page": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.page) = $event)),
          onRowUpdate: _ctx.rowUpdate,
          onOnLoad: _ctx.changePage,
          onSearchChange: _ctx.searchChange,
          onSortableChange: _ctx.sortableChange
        }, null, 8, ["data", "option", "page", "onRowUpdate", "onOnLoad", "onSearchChange", "onSortableChange"])
      ]))
    : _createCommentVNode("", true)
}